@charset "UTF-8";

/* ============================= 
  基本設定
 ============================= */

@font-face {
  font-family: 'Noto Sans Japanese';
  src: url(./Fonts/NotoSansCJKjp-Bold.woff);
  font-weight: bold;
}

@font-face {
  font-family: 'Noto Sans Japanese';
  src: url(./Fonts/NotoSansCJKjp-Medium.woff);
  font-weight: 500;
}

@font-face {
  font-family: 'Noto Sans Japanese';
  src: url(./Fonts/NotoSansCJKjp-Regular.woff);
  font-weight: normal;
}

* {
  transition-property: color, background-color, background-image, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

html {
  font-size: 62.5%;
  overflow: initial;
}

body {
  background-color: #f7f7f7;
  color: #404040;
  font-family: 'Noto Sans Japanese';
  line-height: 1.6875;
  font-size: 1.4rem;
  overflow-wrap: break-word;
  overflow-y: scroll;
}
